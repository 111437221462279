@use 'sass:map';

@mixin iot4bos-ui-asset-groups-manage-related-assets($theme) {
  $grey50: map.get($theme, al-color-palette-grey50);
  $red300: map.get($theme, al-color-palette-red300);

  [cdkdragboundary='.manage-related-assets__drop-boundary'] {
    & :not(.cdk-drag-placeholder) > .iot-platform-ui-overview-card {
      box-shadow: none !important;
      border-radius: 0px !important;
    }
  }

  .manage-related-assets {
    .cdk-drag-placeholder {
      min-height: 106px;
    }

    &__section {
      background-color: $grey50;
    }

    &--max-assets-reached {
      color: $red300;
    }
  }
}
