@use 'sass:map';

@mixin iot4bos-drawer-card-theme($theme) {
  .drawer-card {
    &__content {
      overflow-y: auto;
      height: 100vh;
      padding: 0 16px !important;
    }
  }
}
