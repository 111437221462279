@use 'sass:map';

@mixin iot4bos-ui-site-association-by-asset($theme) {
  $grey50: map.get($theme, al-color-palette-grey50);
  $grey100: map.get($theme, al-color-palette-grey100);
  $text-primary: map.get($theme, al-color-text-primary);

  .association-by-asset {
    .overview-card {
      box-shadow: none !important;
    }

    .default-card {
      cursor: default;
    }

    &__asset-group {
      background-color: $grey100;
    }

    &__name {
      color: $text-primary;
    }

    &__asset {
      background-color: white;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    }

    &__device {
      .overview-card {
        background-color: $grey50 !important;
      }
    }
  }
}
