@use 'sass:map';

@mixin iot4bos-ui-site-association-by-device($theme) {
  $grey50: map.get($theme, al-color-palette-grey50);
  $grey100: map.get($theme, al-color-palette-grey100);

  .association-by-device {
    .overview-card {
      box-shadow: none !important;
    }

    .default-card {
      cursor: default;
    }

    &__device {
      background-color: white;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    }

    &__asset {
      .overview-card {
        background-color: $grey50 !important;
      }
    }
  }
}
